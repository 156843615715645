<template>
  <div
    id="order-documents"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title ">
        Dokumenty
      </h4>
      <div class="actions">
        <button
          :disabled="!globals.user"
          :title="!globals.user ? 'Hromadné sťahovanie súborov je umožnené len registrovaným používateľom' : ''"
          v-if="order.attachments && order.attachments.length > 0"
          class="btn btn-tenderia-grey-blue"
          @click.prevent="downloadDocuments()"
        >
          <i class="icon-download" /> Stiahnuť všetky dokumenty
        </button>
      </div>
    </div>
    <v-table
      :columns="documentsColumns"
      :items="order.attachments"
      :selected="selectedRows"
      item-key="id"
      class="light"
      @row-click="toggleRowDetail"
    >
      <template #cell(name)="{ _item, value }">
        <div class="row no-gutters align-items-center attachment-name">
          <i class="icon-doc-1 mr-2" />
          <span v-text="value" />
        </div>
      </template>
      <template #cell(type)="{ item }">
        {{ $format.formatDocumentType(item.type) }}
      </template>
      <template #cell(date)="{ item }">
        {{ $format.formatDate(item.createdAt) }}
      </template>
      <template #cell(updatedDate)="{ item }">
        {{ $format.formatDateTime(item.updatedAt) }}
      </template>
      <template #cell(actions)="{ item }">
        <a
          v-if="globals.user"
          title="Stiahnuť všetky súbory"
          @click.stop="downloadDocument(item.id)"
        >
          <i class="icon-download" />
        </a>
        <span
          v-else
          class="disabled-link"
          title="Hromadné sťahovanie súborov je umožnené len registrovaným používateľom"
          @click.stop=""
        >
          <i class="icon-download" />
        </span>
      </template>
      <template #item-detail="{ item }">
        <div class="attachments-list row no-gutters">
          <!-- nested padding container for better resize animation -->
          <div class="py-3">
            <div
              v-for="attachment in attachments[item.id]"
              :key="attachment.id"
              class="attachment row no-gutters align-items-center"
            >
              <div class="row no-gutters align-items-center">
                <div class="file-icon"><i :class="fileClass(attachment)" class="fa"></i></div>
                <file-download-link class="tw-relative after:tw-bg-red-500 after:tw-absolute after:tw-h-1 after:tw-w-0 after:tw-bottom-0 after:tw-left-0 hover:after:tw-w-full after:tw-transition-all after:tw-duration-300 tw-cursor-pointer" :href="globals.backendHost + attachment.link + '?token=' + globals.authToken" :label="attachment.name" :file="attachment" />
                <!--<a
                  class="icon row align-items-center no-gutters"
                  :href="globals.backendHost + attachment.link + '?token=' + authToken"
                  target="_blank"
                >
                  {{ attachment.name }}
                  <i class="icon-download ml-2" />
                </a>-->
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-table>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import FileDownloadLink from '@/components/utils/FileDownloadLink.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserDocuments',
  components: { VTable, FileDownloadLink },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      authToken: localStorage.getItem('token'),
      selectedRows: [],
      attachments: {}
    }
  },
  computed: {
    documentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'date',
          label: 'Dátum'
        },
        {
          key: 'updatedDate',
          label: 'Dátum aktualizácie'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  methods: {
    async toggleRowDetail (item) {
      if (!this.attachments[item.id]) {
        await this.fetchFiles(item.id)
      }
      this.selectedRows = this.selectedRows.includes(item.id)
        ? this.selectedRows.filter(i => i !== item.id)
        : [...this.selectedRows, item.id]
    },
    fileExt (file) {
      return this.$format.formatFileType(file.mime)
    },
    fileClass (file) {
      const fileExt = this.fileExt(file)
      return {
        'icon-pdf text-danger': fileExt === 'pdf',
        'icon-doc text-primary': fileExt === 'doc' || fileExt === 'docx' || fileExt === 'odt' || fileExt === 'rtf',
        'icon-excel text-success': fileExt === 'xls' || fileExt === 'xlsx' || fileExt === 'ods',
        'icon-archive': fileExt === 'zip' || fileExt === 'rar',
        'icon-doc-1': fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif'
      }
    },
    async fetchFiles (id) {
      const fetchParams = {
        parent: id,
        limit: 1000
      }
      let fileData = {}
      if (this.globals.authToken) {
        const { data } = await this.$http.post('/file/list', fetchParams)
        fileData = data
      } else {
        const { data } = await this.$http.post('/file/catalog', fetchParams)
        fileData = data
      }
      this.attachments[id] = fileData.files
    },
    showLoading () {
      $('#order-documents .card-block').LoadingOverlay('show')
    },
    hideLoading () {
      $('#order-documents .card-block').LoadingOverlay('hide')
    },
    async downloadDocument (id) {
      this.ShowLoading({ title: 'Pripravujem súbory na stiahnutie' })
      var data = {
        file: {
          id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    },
    async downloadDocuments () {
      this.ShowLoading({ title: 'Pripravujem dokumenty na stiahnutie' })
      var data = {
        order: {
          id: this.order.id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled-link {
  color: gray;
  cursor: not-allowed;
}
.attachments-list {
  background-color: rgba(62, 151, 239, 0.05); // transparent bg to keep shadow under it visible
  border-top: 1px solid #E0E0E0;
}

.attachments-list a, a:link, a:visited
{
  text-decoration: none;
  position: relative;
  padding-bottom: 2px;
}

.attachments-list a:hover
{
  color: #a00;
}

.attachments-list a::before
{
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, #a00, #f00);
  background-size: 0 2px;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size .2s ease-in;
}

.attachments-list a:hover::before
{
  background-size: 100% 2px;
}

.attachment
{
  margin-left: 36px;
}

.attachment-name
{
  cursor: pointer;
  color: #3e97ef;
  font-weight: bold;
}
</style>
